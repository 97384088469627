.Does {
	padding: 20px;
}
.Does h2 {
	margin: 0 auto;
}
.Does small {
	font-style: italic;
}
.Does-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
}
.Does-list li {
	border: 1px solid green;
	display: inline-block;
	margin: 10px 20px 10px 0;
	padding: 2px 5px;
}