.Nav {
  align-items: top;
  background-color: #282c34;
  display: flex;
  font-size: calc(13px + 1vmin);
  justify-content: space-around;
  line-height: calc(15px + 1vmin);
  list-style-type: none;
  margin: 0;
  padding: calc(20px + 1vmin) calc(10px + 1vmin);
}

.Nav-list-item {
  display: inline-block;
  max-width: 20vw;
  text-align: center;
}

.Nav-link {
  color: #61dafb;
  text-decoration: underline;
}

.Nav-cycler {
  display: inline;
}

.Nav-cycle {
  display: none;
}

.Nav-cycle.active {
  display: inline;
}

@media screen and (max-width: 768px) {
  .Nav {
    font-size: calc(7.5px + 1vmin);
  }
}