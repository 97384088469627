.What {
}
.What-button {
	background: red;
	border: 1px solid green;
	font-size: 20px;
	padding: 5px 20px;
	text-decoration: none;
	z-index: 2;
	position: fixed;
	transform-origin: top right;
	right: 0;
	top: calc(55px + 3vmin);
	transform: rotate(-90deg) translateY(-100%);
}
.What-button:hover {
	background: darkred;
	color: white;
}